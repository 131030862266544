import React, { useState, useEffect } from 'react';
import { Divider, Grid, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  score: {
    // margin: theme.spacing(3),
    border: '2px solid #3f51b5',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 10
  }
}));

export default function UserCompensationDetails(props) {
  const classes = useStyles();
  const { className, userid, data, ...rest } = props;
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.skill}>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Typography gutterBottom variant="h4">
                Hobbies
              </Typography>
              <Divider gutterBottom />
            </Grid>
            <Grid xs={12} item>
              {data?.hobbiesSaved?.map((item) => (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: 10 }}>
                  {' '}
                  {item.value}
                </Button>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}
