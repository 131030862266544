import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { sendHttpCall } from 'shareService/httpService';
import {
  UserHiringOrganisation,
  UserAccountDetails,
  EducationalDetails,
  UserWorkDetails,
  UserContacts,
  UserCompensationDetails
} from './components';

import UserSocialLinks from './components/UserSocialLinks/UserSocialLinks';
import UserResume from './components/UserResume/UserResume';
import UserVolunteerData from './components/UserVolunteerData/UserVolutnteerData';
import UserProjects from './components/UserProjects/UserProjects';
import UserHobbies from './components/UserHobbies/UserHobbies';
import Loader from 'components/Loader'
import UserCertificates from './components/UserCertificates/userCertificates';
import UserAwards from './components/UserAwards/UserAwards'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0)
  },
  icard: {
    margin: theme.spacing(1)
  }
}));

const UserDetail = (props) => {
  const classes = useStyles();
  const [isData, setIsdata] = useState(false);
  const [modes, setModes] = useState({
    hiring: false,
    member: false,
    social: false
  });
  const [selectedMode, setSelectedMode] = useState();
  const [user, setUser] = useState({});
  const [purpose, setPurpose] = useState('');
  const [status, setStatus] = useState(0);
  const [dataFromResume, setDataFromResume] = useState(null);
  const [rcoinHistory, setRcoinHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  async function fetchUserDataFromResume() {
    sendHttpCall(
      'GET',
      `users/resume/${props.match.params.userid}`,
      null,
      props
    ).then((response) => {
      console.log({ response });
      setDataFromResume(response);
      // setWorkDetails(response);
    });
  }
  async function fetchRecruiterCoinHistory() {
    sendHttpCall(
      'GET',
      `users/r-coins/history/${props.match.params.userid}`,
      null,
      props
    ).then((response) => {
      console.log({ response });
      setRcoinHistory(response);
      // setWorkDetails(response);
    });
  }
  const refreshData = () => {
    console.log('refreshing data');
  };
  useEffect(() => {
    setLoading(true)
    fetchUserDataFromResume();
    sendHttpCall(
      'GET',
      `users/user_detail/${props.match.params.userid}`,
      null,
      props
    )
      .then((response) => {
        let lookingFor = '';
        setLoading(false)
        console.log(response);
        // let reviewCount = 0;
        response.purpose.map((element) => {
          lookingFor = lookingFor + element + ', ';
        });
        lookingFor = lookingFor.substring(0, lookingFor.length - 2);
        response.lookingFor = lookingFor;
        setModes(response.modes);
        if (window.location.href.includes('=SOCIAL') && response.modes.social)
          setSelectedMode('SOCIAL');
        else if (response.modes.hiring) {
          setSelectedMode('HIRING');

          fetchRecruiterCoinHistory();
        } else if (response.modes.member) setSelectedMode('MEMBER');
        else if (response.modes.social) setSelectedMode('SOCIAL');
        setUser(response);
        setPurpose(lookingFor);
      })
      .catch((e) => {});
  }, []);

  return (
    <>
    {loading && (
            <div className="active_jobs__posts-loading"><Loader/></div>
          )}
    {!loading &&
    <div className={classes.root}>
      <Grid container>
        <Grid xs={12} className={classes.icard}>
          <UserAccountDetails
            userid={props.match.params.userid}
            user={user}
            ogprops={props}
            modes={modes}
            dataFromResume={dataFromResume || {}}
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
            refreshData={refreshData}
            rcoinHistory={rcoinHistory}
          />
        </Grid>
        {(user.status === 2 || selectedMode === 'HIRING') && (
          <Grid xs={12} className={classes.icard}>
            <UserHiringOrganisation
              userid={props.match.params.userid}
              user={user}
            />
          </Grid>
        )}
        
        {(user.status === 2 || selectedMode === 'MEMBER') && (
          <>
            <Grid xs={12} className={classes.icard}>
              <UserWorkDetails
                user={user}
                userid={props.match.params.userid}
                dataFromResume={dataFromResume || {}}
              />
            </Grid>
          </>
        )}
      

        {(user?.jobData || user?.freelanceData || user?.entrepreneurData) &&
          (user.status === 2 || selectedMode === 'MEMBER') && (
            <Grid xs={12} className={classes.icard}>
              <UserCompensationDetails
                userid={props.match.params.userid}
                user={user}
              />
            </Grid>
          )}
        {(user.status === 2 || selectedMode === 'MEMBER') && (
          <Grid xs={12} className={classes.icard}>
            <UserSocialLinks userid={props.match.params.userid} user={user} />
          </Grid>
        )}
        {(user.status === 2 || selectedMode === 'MEMBER') &&
          !!user.resumes?.length && (
            <Grid xs={12} className={classes.icard}>
              <UserResume
                data={dataFromResume || {}}
                userid={props.match.params.userid}
                user={user}
              />
            </Grid>
          )}

        {(user.status === 2 ||
          selectedMode === 'MEMBER' ||
          selectedMode === 'SOCIAL') && (
          <Grid xs={12} className={classes.icard}>
            <UserVolunteerData userid={props.match.params.userid} user={user} />
          </Grid>
        )}
        {(user.status === 2 ||
          selectedMode === 'MEMBER' ||
          selectedMode === 'SOCIAL') && (
          <Grid xs={12} className={classes.icard}>
            <UserProjects
              userid={props.match.params.userid}
              data={dataFromResume || {}}
            />
          </Grid>
        )}
        {(user.status === 2 ||
          selectedMode === 'MEMBER' ||
          selectedMode === 'SOCIAL') && (
          <Grid xs={12} className={classes.icard}>
            <UserHobbies
              userid={props.match.params.userid}
              data={dataFromResume || {}}
            />
          </Grid>
        )}

        {(user.status === 2 ||
          selectedMode === 'MEMBER' ||
          selectedMode === 'SOCIAL') && (
          <Grid xs={12} className={classes.icard}>
            <UserCertificates
              userid={props.match.params.userid}
              data={dataFromResume || {}}
            />
          </Grid>
        )}
        {(user.status === 2 ||
          selectedMode === 'MEMBER' ||
          selectedMode === 'SOCIAL') && (
          <Grid xs={12} className={classes.icard}>
            <UserAwards
              userid={props.match.params.userid}
              data={dataFromResume || {}}
            />
          </Grid>
        )}

        

        <Grid xs={12} className={classes.icard}>
          <UserContacts userid={props.match.params.userid} name={user.name} />
        </Grid>
      </Grid> 
    </div>
    }
    </>
  );
};

export default UserDetail;
